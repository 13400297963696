.dropdown:hover .dropdown-menu {
  display: block;
}

body, input {
  color: #333;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

h1, h2, h3 { font-weight: bold; }
h1 { font-size: 1.2em; }
h2 { font-size: 1.1em; }
h3 { font-size: 1em; }
pre { color: #aaa; }
li { margin-left: 20px; }

#authui-container {
  padding: 20px;
  width: 40vw;
  min-width: 400px;
}

pre, .code-area {
  font-family:  'Fira code', 'Fira Mono', monospace;
  font-size: 0.9em;
}

.editorBox {
  display: flex;
}
.editorPanel {
  width: 50%;
}
.outputPanel {
  width: 50%;
}

/* --- ACE Editor custom styles --- */

.ace_content {
  padding: 5px;
}